import { NonIdealState, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import { Routes, Colors } from '../config';
import {
  BusinessesContext,
  BusinessesContextProvider,
  IsUserAdminContext,
  IsUserAdminContextProvider,
  UIContext,
} from '../contexts';

import ViewBusinessModal from '../components/Modal/ViewBusiness';

import { Imgix } from '../utilities';

const Container = styled.div`
  margin: auto;
  padding: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SearchContent = styled.ul`
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
`;

const SearchItem = styled.li`
  height: 40px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: ${Colors.BP_BACKGROUND};

  :hover {
    background-color: ${Colors.PRIMARY_LIGHT};
  }

  > img {
    height: 30px;
    width: 30px;
    border-radius: 15px;
  }
  > p {
    margin-left: 10px;
    font-size: 12px;
    color: black;
  }
`;

const AdminPage: React.FunctionComponent = ({}) => {
  /*
  const isAdmin = React.useContext(IsUserAdminContext);
  const businesses = React.useContext(BusinessesContext);
  const ui = React.useContext(UIContext);

  const [businessQuery, setBusinessQuery] = React.useState<string>('');

  return !isAdmin ? (
    <Container>
      <div className="bp3-input-group .modifier">
        <span className="bp3-icon bp3-icon-search" />
        <input
          className="bp3-input"
          type="search"
          placeholder="Search for a business..."
          dir="auto"
          onChange={e => setBusinessQuery(e.target.value)}
          value={businessQuery}
        />
      </div>
      <Collapse isOpen={businessQuery.length > 0}>
        <SearchContent>
          {businesses
            .filter(business =>
              business.name.toLowerCase().includes(businessQuery.toLowerCase())
            )
            .map(business => (
              <SearchItem
                key={business.id}
                onClick={() => {
                  ui.modal.show({
                    body: <ViewBusinessModal business={business} />,
                  });
                }}
              >
                <img
                  src={Imgix.createUrl(business.logo, {
                    height: 30,
                    width: 30,
                    cornerRadius: 15,
                    mask: 'CORNERS',
                  })}
                />
                <p>{business.name}</p>
              </SearchItem>
            ))}
        </SearchContent>
      </Collapse>
    </Container>
  ) : (
    <NonIdealState
      icon={IconNames.ERROR}
      title="Error"
      description="You are not an admin."
    >
      <Link to={Routes.MAP} className="bp3-button">
        Go back
      </Link>
    </NonIdealState>
  );
  */

  return null;
};

const AdminPageRoot = () => (
  <IsUserAdminContextProvider>
    <BusinessesContextProvider>
      <AdminPage />
    </BusinessesContextProvider>
  </IsUserAdminContextProvider>
);

export default AdminPageRoot;
